import React, { useState, useEffect } from 'react';

interface CarouselItem {
  image: string;
  title: string;
  text: string;
}

interface CarouselProps {
  items: CarouselItem[];
  interval?: number; // Intervalo de tempo em milissegundos
}

const Carousel: React.FC<CarouselProps> = ({ items, interval = 5000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
    }, interval);

    return () => clearInterval(timer); // Limpa o intervalo quando o componente é desmontado
  }, [items.length, interval]);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + items.length) % items.length);
  };

  return (
    <div className="relative w-2/5 max-w-4xl mx-auto shadow-transparent rounded-lg overflow-hidden bg-transparent">
      <div className="aspect-w-16 aspect-h-6">
        <div
          className="flex transition-transform duration-500 ease-in-out"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {items.map((item, index) => (
            <div key={index} className="w-full h-full flex-shrink-0 relative">
              <img
                src={item.image}
                alt={`Slide ${index}`}
                className="w-full h-full object-contain object-center"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-75"></div>
              <div className="absolute inset-x-0 bottom-0 p-4 text-white">
                <h2 className="text-2xl font-bold mb-2">{item.title}</h2>
                <p className="text-base">{item.text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <button
        onClick={prevSlide}
        className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full opacity-75 hover:opacity-100 focus:outline-none"
      >
        &#10094;
      </button>
      <button
        onClick={nextSlide}
        className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full opacity-75 hover:opacity-100 focus:outline-none"
      >
        &#10095;
      </button>
    </div>
  );
};

export default Carousel;